export interface LinkProps {
  name: string;
  url: string;
}

export interface PagesProps {
  code: number;
  name: string;
  perfil?: string;
  links?: LinkProps[];
  fatherCode?: number;
  page?: number;
}

const pagesAdimin: PagesProps[] = [
  {
    code: 1,
    name: 'Gestão Pedido',
    perfil: 'Loja',
    page: 0,
    links: [
      {
        name: 'Pedido',
        url: '/Order',
      },
    ],
  },
  {
    code: 2,
    name: 'Cadastro',
    perfil: 'ADM',
    page: 0,
    links: [
      {
        name: 'Lojas',
        url: '/Register/Store',
      },
      {
        name: 'Usuário',
        url: '/Register/Users',
      },
    ],
  },
  {
    code: 3,
    name: 'Ocorrência',
    perfil: 'ADM',
    page: 0,
    links: [
      {
        name: 'Dashboard',
        url: '/Occurence/Dashboard',
      },
      {
        name: 'Consulta',
        url: '/Occurence/consultation',
      },
    ],
  },
];

export default pagesAdimin;
