/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: User: admin@pda.com.br Password: 123456

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { BiLoaderAlt } from 'react-icons/bi';

import Logo from '../../assets/images/logo.png';
import EmailIcon from '../../assets/svg/email.svg';
import EmailIconError from '../../assets/svg/email-error.svg';
import PasswordIcon from '../../assets/svg/password.svg';
import PasswordIconError from '../../assets/svg/password-error.svg';

import { useAuth } from '../../hooks/Auth/index';
import { useToast } from '../../hooks/Toast';

import { Container, Form } from './styles';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface SignInFormData {
  uid: string;
  password: string;
}

interface SignInResponse {
  data: {
    changePassword: boolean;
  };
}

interface ForgotPasswordData {
  email: string;
}

interface ChangePasswordData {
  token: string;
  password: string;
  confirmPassword: string;
}

const SignIn: React.FC = () => {
  const { signIn, signOut } = useAuth();
  const { ToastError } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userInformation, setUserInformation] = useState({
    token: null,
    id: null,
    email: '',
  });

  const handleChangePassword = useCallback(
    async (data: ChangePasswordData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        const schema = Yup.object().shape({
          token: Yup.string()
            .required('Token obrigatório')
            // eslint-disable-next-line func-names
            .test('token-match', 'O token não corresponde.', function (value) {
              return value === userInformation.token;
            }),
          password: Yup.string().required('A sua senha é obrigatória'),
          confirmPassword: Yup.string()
            .required('A confirmação da senha é obrigatória')
            .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const body = {
          email: userInformation.email,
          password: data.password,
          token: data.token,
        };

        const response = await api.put(
          `/users/password/${userInformation.id}`,
          body
        );

        setChangePassword(false);
        setForgotPassword(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        ToastError({
          message: 'Ocorreu um erro, tente novamente mais tarde.',
        });
      }
    },
    [ToastError, userInformation]
  );

  const handleForgotPassword = useCallback(
    async (data: ForgotPasswordData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        const schema = Yup.object().shape({
          email: Yup.string().email().required('Seu email é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/users/forgotPassword/', data);

        setChangePassword(true);
        setForgotPassword(false);
        setLoading(false);
        setUserInformation({
          token: response.data[0].token,
          id: response.data[0].id,
          email: data.email,
        });
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        ToastError({
          message: 'Usuário com esse e-mail não encontrado.',
        });
      }
    },
    [ToastError]
  );

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        const schema = Yup.object().shape({
          uid: Yup.string().required('Seu nome ou email é obrigatório'),
          password: Yup.string().required('A sua senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn({
          uid: data.uid,
          password: data.password,
        }).then((res: any) => {
          setLoading(false);
          history.push('/Order');
        });
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        ToastError({
          message: 'Ocorreu um erro, tente novamente mais tarde.',
        });
      }
    },
    [signIn, history, ToastError]
  );

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <div className="backgroundImage">
        <div className="overlay" />
        <div className="background">
          <div className="logo">
            <img src={Logo} alt="Logo" />
            <p>
              O Portal Oms Loja, foi desenvolvido para permitir de maneira ágil
              e simples gerecial os pedidos na loja fisica.
            </p>
          </div>
          <div className="copy">
            <p>© PDA Soluções</p>
            <div className="footerImage">
              <a href="terms">Versão 3.25</a>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="form">
            {!forgotPassword && !changePassword && (
              <>
                <h4>Faça login para entrar na plataforma.</h4>
                <Form
                  onSubmit={handleSubmit}
                  ref={formRef}
                  loading={loading || undefined}
                >
                  <div className="input">
                    <Input
                      icon={EmailIcon}
                      iconError={EmailIconError}
                      name="uid"
                      placeholder="Seu nome ou email"
                    />
                  </div>
                  <div className="input">
                    <Input
                      icon={PasswordIcon}
                      iconError={PasswordIconError}
                      type="password"
                      name="password"
                      placeholder="Sua senha"
                    />
                  </div>
                  <button type="submit">
                    {loading ? (
                      <BiLoaderAlt size={24} color="#fff" />
                    ) : (
                      'Acessar'
                    )}
                  </button>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => setForgotPassword(true)}
                  >
                    Esqueceu a senha ?
                  </a>
                </Form>
              </>
            )}

            {forgotPassword && (
              <>
                <h4>
                  Esqueceu sua senha? Digite seu email para resetar sua senha.
                </h4>
                <Form
                  onSubmit={handleForgotPassword}
                  ref={formRef}
                  loading={loading || undefined}
                >
                  <div className="input">
                    <Input
                      icon={EmailIcon}
                      iconError={EmailIconError}
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <button type="submit">
                    {loading ? (
                      <BiLoaderAlt size={24} color="#fff" />
                    ) : (
                      'Enviar'
                    )}
                  </button>
                </Form>
              </>
            )}

            {changePassword && (
              <>
                <h4>Por favor informar abaixo nova senha.</h4>
                <Form
                  onSubmit={handleChangePassword}
                  ref={formRef}
                  loading={loading || undefined}
                >
                  <div className="input">
                    <Input
                      icon={PasswordIcon}
                      iconError={PasswordIconError}
                      name="token"
                      placeholder="Token"
                    />
                  </div>
                  <div className="input">
                    <Input
                      icon={PasswordIcon}
                      iconError={PasswordIconError}
                      type="password"
                      name="password"
                      placeholder="Sua senha"
                    />
                  </div>
                  <div className="input">
                    <Input
                      icon={PasswordIcon}
                      iconError={PasswordIconError}
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirme sua senha"
                    />
                  </div>
                  <button type="submit">
                    {loading ? (
                      <BiLoaderAlt size={24} color="#fff" />
                    ) : (
                      'Salvar'
                    )}
                  </button>
                </Form>
              </>
            )}
          </div>
          <div className="footerForm">
            <a href="terms">Termos de uso</a>
            <a href="help">Help</a>

            <a href="contact">Contato</a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
