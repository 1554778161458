/* eslint-disable no-console */
import React, { useCallback, useState, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';

import { useAuth } from '../../hooks/Auth';

import { Container, SideMenu, Main } from './styles';

import logo_oms from '../../assets/svg/logo_oms.svg';

import LogoImageBlack from '../../assets/images/logo-black.png';

import Options from './Options';
import ItemLink from './ItemLink';

import pagesAdmin from './Options/pages';
import pagesCommon from './mock/pageCommon';
import Footer from '../../components/Footer';

import RegisterIcon from '../../assets/svg/register-dashboard-icon.svg';
import ArrowLeft from '../../assets/svg/link-arrow-left.svg';

import api from '../../services/api';
// import Notification from '../../components/Notification';

interface NotifyProps {
  nomeStatus: string;
  descStatus: string;
  valorAtual: string;
}

const SignedLayout: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [closed, setClosed] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [mobile, setMobile] = useState(false);
  const role = localStorage.getItem('@pdamodules::role');
  const [, setStatus] = useState([] as NotifyProps[]);

  // useEffect(() => {
  //   async function getStatus() {
  //     try {
  //       const data = {};

  //       const statusResponse = await api.get('Statuses', {
  //         ...data,
  //       });

  //       setStatus(statusResponse.data);
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       console.log('Sucesso');
  //     }
  //   }

  //   getStatus();
  // }, []);

  const [pageDefault, setPageDefault] = useState('');

  const windowResize = useCallback(() => {
    if (window.innerWidth <= 910) {
      setHovered(false);
      setClosed(false);
      setMobile(true);

      return;
    }

    setClosed(false);
    setMobile(false);
  }, []);

  useEffect(() => {
    windowResize();
    window.addEventListener('resize', windowResize);
  }, [windowResize]);

  const handleChangeMenuState = useCallback(() => {
    setClosed(prevState => !prevState);
    setHovered(false);
  }, []);

  const handleHover = useCallback(state => {
    if (window.innerWidth <= 910) {
      setHovered(false);
      setClosed(true);
      return;
    }

    setHovered(state);
  }, []);

  useEffect(() => {
    setPageDefault('dashboard');
  }, []);

  return (
    <Container closed={closed}>
      <header>
        {mobile && (
          <div className="logo">
            <img src={LogoImageBlack} alt="" />
          </div>
        )}
        <div className="right">
          {/* <Notification /> */}
          <div className="name">
            <p>
              Olá,
              <strong>{` ${user?.name}`}</strong>
            </p>
          </div>
          <Options />
          {mobile && (
            <button
              type="button"
              className="menu-mobile"
              onClick={() => setClosed(prevState => !prevState)}
            >
              <FiMenu size={40} color="#484F66" />
            </button>
          )}
        </div>
      </header>
      <SideMenu
        closed={closed}
        hovered={hovered}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <div className="content">
          <div className="top">
            <div className="container">
              <div className="logo">
                <img src={logo_oms} alt="" />
              </div>
              <button
                onClick={handleChangeMenuState}
                type="button"
                className="arrow"
              >
                <img src={ArrowLeft} alt="" />
              </button>
            </div>
          </div>
          <div className="links-list">
            {role === 'admin' &&
              pagesAdmin.map(page => (
                <ItemLink
                  key={page.name}
                  title={page.name}
                  icon={RegisterIcon}
                  closed={closed ? !hovered : false}
                  links={page.links}
                />
              ))}
            {role === 'common' &&
              pagesCommon.map(page => (
                <ItemLink
                  key={page.name}
                  title={page.name}
                  icon={RegisterIcon}
                  closed={closed ? !hovered : false}
                  links={page.links}
                />
              ))}
          </div>
        </div>
      </SideMenu>
      <Main onClick={() => mobile && setClosed(false)} id="main">
        {children}
        <footer className="footer">
          <Footer />
        </footer>
      </Main>
    </Container>
  );
};

export default SignedLayout;
