import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  padding: 1rem 1rem;
`;

export const FileUploadTitle = styled.h3`
  color: gray;
  opacity: 0.5;
  font-weight: 500;
  font-size: clamp(14px, 1rem, 5vmin);
  text-align: center;
`;

export const FileUploadImage = styled.img`
  max-width: 100%;
  display: block;
  object-fit: cover;
  user-select: none;
`;

export const FileUploadButton = styled.label`
  color: #fff;
  background-color: white;
  display: inline-flex;
  border: 1px solid #dfdfdf;
  align-items: center;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px 20px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

export const FileNamesBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
`;

export const FileName = styled.span`
  color: #0095e8bb;
  opacity: 0.825;
  font-size: 12px;
  font-weight: 500;
`;
