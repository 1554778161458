import { FormHandles } from '@unform/core';
import { text } from 'express';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { transformToSelect } from '../../../../utils/toSelect';
import {
  handleCreateUserData,
  handleStoreData,
} from '../api/RegisterUsers.api';
import { ICreateUsersProps, IStoreData } from '../types';

const CreateUsers: React.FC<ICreateUsersProps> = ({
  isOpen,
  handleClose,
  rechargeUsersData,
  store,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const createInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();

  const roles = [
    { label: 'admin', value: 'admin' },
    { label: 'storage', value: 'storage' },
  ];

  const hanldleCreate = useCallback(
    async (data: any) => {
      setIsModalLoading(true);
      await handleCreateUserData(data)
        .then(() => {
          Toastsuccess({
            message: `Usuário criado com sucesso!`,
          });
        })
        .catch(err => {
          ToastError({ message: `Falha ao criar,Usuário já existe!` });
          handleClose('edit');
        })
        .finally(() => {
          rechargeUsersData({
            search: null,
            status: false,
          });
          handleClose('edit');
          setIsModalLoading(false);
        });
    },
    [ToastError, Toastsuccess, handleClose, rechargeUsersData]
  );

  return (
    <>
      <PageMode
        isModal
        open={isOpen}
        modalLoading={isModalLoading}
        handleClose={() => handleClose('edit')}
        modalRefObject={createInputsRef}
        submitButtonTitle="Salvar"
        submitButtonIcon
        title="Cadastro Usuário"
        handleSubmit={data => hanldleCreate(data)}
        inputs={[
          {
            name: 'first_name',
            label: 'Nome',
            placeholder: 'Digite o nome',
            type: 'text',
            isRequired: true,
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'email',
            label: 'Email',
            isRequired: true,
            placeholder: 'Digite o email',
            type: 'text',
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'branch_id',
            label: 'Loja',
            placeholder: 'Selecione a loja',
            type: 'select',
            options: store,
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'username',
            label: 'Login',
            isRequired: true,
            placeholder: 'Digite o nome de usuário com no mínimo 4 caracteres',
            type: 'text',
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'password',
            label: 'Senha',
            isRequired: true,
            placeholder: 'Digite a senha de usuário',
            type: 'text',
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'role',
            label: 'Perfil',
            placeholder: 'Selecione o Perfil',
            type: 'select',
            options: roles,
            xl: 4,
            lg: 4,
            xs: 12,
            md: 6,
            sm: 6,
          },
          {
            name: 'status',
            label: 'Está ativo ?',
            type: 'switch',
            isDisabled: isModalLoading,
            defaultChecked: true,
          },
        ]}
      />
    </>
  );
};
export default CreateUsers;
