import React, { useCallback, useState } from 'react';
import { Container, TableContainer } from '../styles/styles';
import deleteIcon from '../../../../assets/svg/deleteActionsIcon.svg';
import editIcon from '../../../../assets/svg/editIcon.svg';
import lockIcon from '../../../../assets/svg/lock.svg';
import TagStatus from '../../../../components/TagStatus';
import { Table } from '../../../../components/NewTable';
import EditUsers from '../EditUsers';
import DeleteUsers from '../DeleteUsers';
import { IUsersTableProps, IUserData } from '../types';
import EditUserPassword from '../EditUserPassword';

const UserTable: React.FC<IUsersTableProps> = ({
  storeData,
  store,
  rechargeUsersData,
}) => {
  const [isDeleteStoreOpen, setIsDeleteStoreModalOpen] = useState(false);
  const [isEditStoreOpen, setIsEditStoreModalOpen] = useState<boolean>(false);
  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] =
    useState<boolean>(false);
  const [currentData, setCurrentData] = useState<IUserData>({} as IUserData);

  const handleStatus = useCallback((res: any) => {
    return res === true ? 'Ativo' : 'Inativo';
  }, []);

  const handleCloseAnyModal = useCallback(
    (modalType: 'edit' | 'delete' | 'password') => {
      switch (modalType) {
        case 'delete':
          setIsDeleteStoreModalOpen(false);
          break;
        case 'edit':
          setIsEditStoreModalOpen(false);
          break;
        case 'password':
          setIsEditPasswordModalOpen(false);
          break;
        default:
          setIsDeleteStoreModalOpen(false);
          break;
      }
    },
    []
  );

  return (
    <>
      <Container>
        <TableContainer>
          <Table<IUserData>
            columns={[
              {
                title: 'ID',
                type: 'stringCenter',
                orderable: true,
                props: ['id'],
                cssProps: {
                  width: '1%',
                },
                renderItem: row => (
                  <div className="orderColumn">
                    <p style={{ marginLeft: '10px' }}>{row.id}</p>
                  </div>
                ),
              },
              {
                title: 'Nome',
                type: 'string',
                orderable: true,
                props: ['first_name'],
                cssProps: {
                  width: '0.5%',
                },
              },
              {
                title: 'Loja',
                type: 'string',
                orderable: true,
                props: ['branch_name'],
                cssProps: {
                  width: '1%',
                },
                cssTitle: {
                  width: '1%',
                },
              },
              {
                title: 'Login',
                type: 'string',
                orderable: true,
                props: ['username'],
                cssProps: {
                  width: '1%',
                },
                cssTitle: {
                  width: '1%',
                },
              },
              {
                title: 'Perfil',
                type: 'string',
                orderable: true,
                props: ['role'],
                cssProps: {
                  width: '15%',
                },
                cssTitle: {
                  width: '1%',
                },
              },
              {
                title: 'Status',
                type: 'stringCenter',
                orderable: true,
                props: ['status'],
                cssProps: {
                  width: '1%',
                  marginRight: '10%',
                },
                renderItem: row => (
                  <TagStatus status={handleStatus(row.status)} />
                ),
              },
              {
                title: 'Ações',
                type: undefined,
                props: [''],
                cssProps: {
                  width: '2%',
                },
                renderItem: row => (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setIsDeleteStoreModalOpen(true);
                        setCurrentData(row);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        style={{ width: '20px' }}
                        alt="delete"
                      />
                      <p className="hover-item">Excluir</p>
                    </button>
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setCurrentData(row);
                        setIsEditStoreModalOpen(true);
                      }}
                    >
                      <img
                        src={editIcon}
                        alt="edit"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Editar</p>
                    </button>
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        setCurrentData(row);
                        setIsEditPasswordModalOpen(true);
                      }}
                    >
                      <img
                        src={lockIcon}
                        alt="edit"
                        style={{ width: '20px' }}
                      />
                      <p className="hover-item">Editar senha</p>
                    </button>
                  </div>
                ),
              },
            ]}
            defaultNumberOfRows={10}
            exportList
            rows={[
              ...storeData.map(item => ({
                ...item,
              })),
            ]}
          />
        </TableContainer>
        <EditUsers
          isOpen={isEditStoreOpen}
          rechargeUsersData={rechargeUsersData}
          handleClose={handleCloseAnyModal}
          currentValue={currentData}
          store={store}
        />
        <EditUserPassword
          isOpen={isEditPasswordModalOpen}
          rechargeUsersData={rechargeUsersData}
          handleClose={handleCloseAnyModal}
          currentValue={currentData}
          store={store}
        />
        {isDeleteStoreOpen && (
          <DeleteUsers
            currentValue={currentData}
            rechargeUsersData={rechargeUsersData}
            handleClose={handleCloseAnyModal}
          />
        )}
      </Container>
    </>
  );
};
export default UserTable;
